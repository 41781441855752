<template>
  <component v-if="content" class="page-content overflow-hidden" :is="tag">
    <section v-for="(block, i) in content.body" :key="i">
      <block-component :content="block" />
    </section>
  </component>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { PageContent } from "./types";

export default defineComponent({
  name: "PageContent",
  props: {
    content: {
      type: Object as PropType<PageContent>,
    },
    tag: { type: String, default: "div" },
  },
});
</script>

<style lang="scss">
.page-content {
  section:not(:last-child) {
    border-bottom: 10px solid transparent;
  }
}
</style>
